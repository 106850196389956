.Totals {
  display: grid;
  grid-template-columns: 1fr 380px;
  grid-gap: 40px; }
.Totals-Products {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  .EmptyText {
    display: flex;
    justify-content: center; } }
.TotalsPanel {
  .PriceBlock {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px; } }
.Totals-Button {
  margin-top: 40px;
  margin-bottom: 24px;
  .Button {
    width: 100%; } }
.Totals-CatalogButtons {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  .Button {
    border-radius: 100px !important; } }

.OfftopSeparator {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-gap: 16px;
  align-items: center;
  color: var(--color-tertiary-6);
  .Separator {
    width: 100%;
    height: 1px;
    background-color: var(--color-tertiary-2); } }

@media screen and ( max-width: 1366px ) {
  .Totals {
    grid-template-columns: 1fr; }
  .TotalsPanel {
    margin-left: -16px;
    margin-right: -16px;
    .PriceBlock {
      padding: 16px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      border-top: 1px var(--color-tertiary-1) solid;
      border-bottom: 1px var(--color-tertiary-1) solid; }
    .Totals-Button {
      margin-top: 24px; }
    .Totals-Button, .Totals-CatalogButtons, .OfftopSeparator {
      padding: 0px 16px; }
    .TotalsText, .Price {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px; }
    .TotalsCaption {
      grid-column: 1/-1; }
    .Price {
      text-align: right; } } }
