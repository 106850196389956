@import "libs";

 :root {
  //Accent Colors
  --color-primary-11: #3C0061;
  --color-primary-10: #5B0E8B;
  --color-primary-8: #7C3EA2;
  --color-primary-6: #8700DB;
  --color-primary-4: #BD9FD1;
  --color-primary-2: #DECFE8;
  --color-primary-1: #EFE7F4;
  --color-secondary-10: #6FD44B;
  --color-secondary-8: #8CDD6F;
  --color-secondary-6: #A9E593;
  --color-secondary-4: #C5EEB7;
  --color-secondary-2: #E2F6DB;
  --color-secondary-1: #F1FBED;
  --color-tertiary-10: #1E2123;
  --color-tertiary-8: #484F56;
  --color-tertiary-6: #737A80;
  --color-tertiary-4: #9FA5AB;
  --color-tertiary-2: #CAD0D5;
  --color-tertiary-1: #E2E6EB;
  --color-tertiary-04: #F1F4F7;
  //Additional Color
  --color-additional-pink-10: #F45096;
  --color-additional-pink-1: #FDDEEB;
  //Functional Colors
  --color-functional-error-regular: #DB3B21;
  --color-functional-error-small: #CC351C;
  --color-functional-error-1: #FCECE9;
  --color-functional-success-regular: #1AAA55;
  --color-functional-success-small: #12753A;
  --color-functional-success-1: #E9F7EE;
  --color-functional-warning-regular: #FC9403;
  --color-functional-warning-small: #A35200;
  --color-functional-warning-1: #FFF5E6;
  --color-functional-info-regular: #1F78D1;
  --color-functional-info-small: #176EC6;
  --color-functional-info-1: #E9F2FB;
  //Shadows
  --shadow-s: 0px 2px 4px rgba(91, 14, 139, 0.02), 0px 2px 4px rgba(30, 33, 35, 0.06);
  --shadow-m: 0px 3px 8px rgba(91, 14, 139, 0.04), 0px 3px 8px rgba(30, 33, 35, 0.1);
  --shadow-l: 0px 4px 8px rgba(91, 14, 139, 0.04), 0px 5px 10px rgba(30, 33, 35, 0.12);

  //Font
  --font-default: 'Inter', sans-serif; }

//Typography
h1 {
  font-weight: 700;
  font-family: var(--font-default);
  font-size: 36px;
  line-height: 44px; }
h2 {
  font-weight: 700;
  font-family: var(--font-default);
  font-size: 24px;
  line-height: 32px; }
h3 {
  font-weight: 700;
  font-family: var(--font-default);
  font-size: 18px;
  line-height: 24px; }
h4 {
  font-weight: 700;
  font-family: var(--font-default);
  font-size: 16px;
  line-height: 24px; }

h1, h2, h3, h4 {
  margin: 0;
  padding: 0; }

.Body-Regular-1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px; }
.Body-Medium-1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px; }
.Body-Regular-2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px; }
.Body-Medium-2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px; }
.Body-Bold-2 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px; }
.Button-Bold {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px; }
.Button-Medium {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px; }
.Caption-Regular {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px; }
.Caption-Medium {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px; }
.Caption-Italic {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px; }
.Overline-Regular {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px; }
.Overline-Medium {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px; }
.Overline-Bold {
  font-weight: 700;
  font-size: 10px;
  line-height: 16px; }

body {
  position: relative;
  font-family: var(--defaultFont);
  min-width: 320px;
  color: var(--color-tertiary-10);
  background-color: #fff;
  display: block; }
ul {
  margin: 0;
  padding: 0;
  list-style: none; }

input, button, textarea {
  outline: none;
  appearance: none; }

a {
  text-decoration: none !important; }
p {
  margin: 0;
  padding: 0; }
.DefaultSection {
  padding: 64px 0px; }

@media screen and ( max-width: 576px ) {
  .DefaultSection {
    padding: 48px 0px; } }
