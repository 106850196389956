.Catalog-Body {
  background-color: var(--color-tertiary-04); }
.Catalog {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 16px;
  .EmptyText {
    grid-column: 1/-1;
    display: flex;
    justify-content: center; } }
.Catalog-Footer {
  display: flex;
  align-items: center;
  .TemplateText {
    color: var(--color-tertiary-6); } }

.Catalog-Buttons {
  width: 300px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 24px;
  margin-left: auto;
  margin-top: 32px;
  .Button {
    width: 100%; } }
.Spinner-Container {
  grid-column: 1/-1;
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    height: 40px; } }

@media screen and ( max-width: 1366px ) {
  .Catalog {
    grid-template-columns: 1fr; }
  .Catalog-Footer {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 16px;
    background-color: #fff;
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
    border-top: 1px var(--color-tertiary-1) solid;
    justify-items: center;
    grid-gap: 16px;
    z-index: 2;
    .Catalog-Buttons {
      grid-template-columns: 1fr;
      width: 100%;
      margin-top: 0;
      grid-gap: 16px;
      .Continue {
        order: 0;
        width: 100%; }
      .Skip {
        order: 1;
        border: 0px !important; } } } }
