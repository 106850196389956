.CartButton {
  position: relative;
  cursor: pointer;
  .Icon {
    font-size: 24px;
    color: #fff; }
  .CartCounter {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: absolute;
    background: var(--color-functional-error-small);
    color: #fff;
    right: -8px;
    top: -8px;
    border: 2px #fff solid; } }
