.Faq-Container {
  display: flex;
  justify-content: center; }
.Questions {
  width: 800px;
  h2 {
    margin-bottom: 24px; }
  .Question {
    padding: 24px 16px;
    border-top: 1px var(--color-tertiary-1) solid; }
  .Question-Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    cursor: pointer;
    .Icon {
      color: var(--color-tertiary-6);
      font-size: 16px; } }
  .Question-Body {
    padding-top: 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    display: none; } }
.Question_status_active {
  .Question-Header {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700; }
  .Icon {
    transform: rotate(180deg); }
  .Question-Body {
    display: block; } }
