.HeaderFormData {
  padding: 12px 16px;
  background-color: var(--color-tertiary-04);
  border-radius: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .Icon {
    color: var(--color-tertiary-4);
    font-size: 16px;
    margin-right: 8px; }
  .Category {
    margin-right: 32px; }
  .Ellips {
    display: inline-block;
    width: 3px;
    height: 3px;
    background-color: var(--color-tertiary-2);
    border-radius: 3px;
    margin: 0px 8px; }
  .Dates {
    color: var(--color-tertiary-8); } }

@media screen and ( max-width: 1366px ) {
  .HeaderFormData {
    background-color: #fff;
    height: 50px;
    border-bottom: 1px var(--color-tertiary-1) solid;
    border-radius: 0; } }
