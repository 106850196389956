.Stepper {
  display: flex;
  justify-content: space-between;
  position: relative; }
.StepBar {
  width: 100%;
  height: 2px;
  background-color: var(--color-tertiary-2);
  position: absolute; }
.Step {
  margin-top: -8px;
  cursor: pointer;
  .Marker {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px var(--color-tertiary-2) solid;
    margin-bottom: 4px;
    background-color: #fff;
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center; }
  .Icon {
    font-size: 6px;
    color: #fff; }
  .Title {
    transform: translateX(-50%);
    margin-left: 8px; } }
.LastStep {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .Title {
    transform: translateX(50%);
    margin-right: 8px; } }
.Step_status_notActive {
  .Title {
    color: var(--color-tertiary-4); } }

.Step_status_done {
  .Marker {
    background-color: var(--color-secondary-10);
    border: 2px var(--color-secondary-10) solid;
    box-shadow: var(--shadow-l); }
  .Title {
    color: var(--color-secondary-10); } }
.Step_status_active {
  .Marker {
    background-color: #fff;
    border: 4px var(--color-secondary-10) solid;
    box-shadow: var(--shadow-l); }
  .Title {
    color: var(--color-secondary-10); } }
