.Contacts-Container {
  display: flex;
  margin: 120px 0px;
  justify-content: center;
  .Text {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    align-content: start;
    margin-left: 80px; }
  .ContactBlock {
    padding: 16px 24px;
    border: 1px var(--color-primary-4) solid;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .Title {
      color: var(--color-primary-6); } }
  .Icon-Container {
    width: 28px;
    height: 28px;
    background-color: var(--color-primary-10);
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    flex-shrink: 0;
    margin-right: 12px; }
  .ContactLine {
    display: flex;
    align-items: center;
    a {
      color: var(--color-tertiary-10); } } }

@media screen and ( max-width: 1366px ) {
  .Contacts-Container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0px;
    padding: 16px;
    grid-gap: 16px;
    img {
      width: 100%; }
    .Text {
      margin-left: 0; } } }
