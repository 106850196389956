.PPaymentMethods {
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-gap: 40px;
  align-items: start; }
.PaymentTypes {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 32px;
  align-items: start; }
.PaymentOptions {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px; }

.PaymentOptionTile {
  padding: 16px;
  border: 1px var(--color-tertiary-2) solid;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 48px 1fr 24px;
  grid-gap: 16px;
  cursor: pointer;
  &:hover {
    box-shadow: var(--shadow-m); }
  .PaymentIcon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--color-tertiary-6);
    background-color: var(--color-tertiary-1);
    border-radius: 12px; }
  .Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px; } }
.PaymentConfig-NextButton {
  grid-column: 1/-1;
  display: flex;
  justify-content: flex-end; }
.PaymentOptionTile_theme_active {
  border: 1px var(--color-primary-6) solid;
  .PaymentIcon {
    background: var(--color-primary-1);
    color: var(--color-primary-10); } }

@media screen and ( max-width: 1366px ) {
  .PPaymentMethods {
    grid-template-columns: 1fr; }
  .PaymentTypes {
    grid-template-columns: 1fr;
    grid-gap: 24px;
    .PaymentOptions {
      grid-gap: 16px; }
    .PaymentConfig-NextButton {
      width: 100%;
      display: block;
      .Button {
        width: 100%; } } } }
