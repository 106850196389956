.Page404 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }
.Page404-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  justify-items: center;
  align-items: center;
  img, p {
    margin-bottom: 24px;
    text-align: center; } }

@media screen and ( max-width: 1366px) {
  .Page404-Container {
    padding: 16px;
    img {
      width: 290px; } } }
