.PasswordRecoveryMessage-Form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  h3 {
    text-align: center; }
  .Text {
    text-align: center;
    color: var(--color-tertiary-8); }
  .Resend-Text {
    display: flex;
    align-items: center;
    justify-content: center;
    .Button {
      margin-left: 4px; } } }
