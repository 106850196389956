.Button {
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  appearance: none !important;
  outline: none !important;
  border: 0px;
  transition: all 0.3s linear;
  transition-property: background, color, border;
  position: relative;
  overflow: hidden;
  .Button-Content {
    display: flex;
    align-items: center; }
  .Button-IconContainer {
    display: flex;
    align-items: center;
    justify-content: center; } }
.Button_icon_position_right {
  .Button-IconContainer {
    order: 1; }
  .Button-Child {
    order: 0; } }

.Button_theme_primary {
  background-color: var(--color-primary-10);
  color: #fff;
  &:hover {
    background-color: var(--color-primary-11); }
  &:active {
    background-color: var(--color-tertiary-10); }
  &:disabled {
    background-color: var(--color-tertiary-2);
    color: var(--color-tertiary-6); } }

.Button_theme_secondary {
  background-color: transparent;
  color: var(--color-primary-10);
  border: 1px var(--color-primary-10) solid;
  &:hover {
    background-color: var(--color-primary-1); }
  &:active {
    border: 1px var(--color-tertiary-10) solid;
    background-color: var(--color-primary-1);
    color: var(--color-tertiary-10); }
  &:disabled {
    background-color: #fff;
    color: var(--color-tertiary-6);
    border: 1px var(--color-tertiary-2); } }

.Button_theme_internal-link {
  background-color: transparent;
  color: var(--color-primary-10);
  padding: 0px !important;
  &:hover {
    color: var(--color-primary-11); }
  &:active {
    color: var(--color-tertiary-10); }
  &:disabled {
    color: var(--color-tertiary-6); } }

.Button_content_empty {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 100% !important; }

.Button_size_48 {
  height: 48px;
  padding: 0px 24px;
  border-radius: 24px;
  .Button_icon_position_left {
    .Button-IconContainer {
      margin-right: 16px; } }
  .Button_icon_position_right {
    .Button-IconContainer {
      margin-left: 16px; } }
  .Icon {
    font-size: 24px; }
  .Button_content_empty {
    width: 48px; } }

.Button_size_40 {
  height: 40px;
  padding: 0px 24px;
  border-radius: 4px;
  .Button_icon_position_left {
    .Button-IconContainer {
      margin-right: 8px; } }
  .Button_icon_position_right {
    .Button-IconContainer {
      margin-left: 8px; } }
  .Icon {
    font-size: 20px; }
  .Button_content_empty {
    width: 40px; } }

.Button_size_32 {
  height: 32px;
  padding: 0px 16px;
  border-radius: 4px;
  .Button_icon_position_left {
    .Button-IconContainer {
      margin-right: 8px; } }
  .Button_icon_position_right {
    .Button-IconContainer {
      margin-left: 8px; } }
  .Icon {
    font-size: 16px; }
  .Button_content_empty {
    width: 32px; } }

.Button_size_24 {
  height: 24px;
  padding: 0px 12px;
  border-radius: 4px;
  .Button_icon_position_left {
    .Button-IconContainer {
      margin-right: 8px; } }
  .Button_icon_position_right {
    .Button-IconContainer {
      margin-left: 8px; } }
  .Icon {
    font-size: 12px; }
  .Button_content_empty {
    width: 24px; } }
