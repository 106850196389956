.ProductTile {
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  box-shadow: var(--shadow-s);
  border: 2px #fff solid;
  cursor: pointer;
  &:hover {
    box-shadow: var(--shadow-l); }
  .Title {
    display: flex;
    align-items: center;
    .Checkbox {
      margin-right: 16px; } }
  .ProductTile-Head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Checkbox_theme_on {
      background-color: var(--color-primary-10);
      border: 2px var(--color-primary-10) solid;
      color: #fff; } }
  .ProductTile-Content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;
    align-items: start;
    .Avatar {
      width: 100%;
      height: 208px;
      span {
        width: 100%;
        height: 100%; }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: top center; } }
    .Options {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      align-items: start;
      .Checkbox_theme_on {
        background-color: var(--color-primary-10);
        color: #fff;
        border: 2px var(--color-primary-10) solid; } } }
  .ProductTile-Controlls {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .QuantityController {
    padding: 8px;
    border-radius: 32px;
    border: 1px var(--color-tertiary-2) solid;
    display: inline-flex;
    align-items: center;
    .Quantity {
      padding: 0px 16px; }
    .Button {
      background-color: var(--color-tertiary-2);
      color: var(--color-tertiary-8); } } }

.ProductTile_theme_in_cart {
  border: 2px var(--color-primary-10) solid; }
