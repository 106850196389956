.Profile {
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-gap: 40px;
  h2 {
    margin-bottom: 24px; } }

@media screen and ( max-width: 1366px ) {
  .Profile {
    grid-template-columns: 1fr;
    .TabsController {
      width: 100%;
      grid-gap: 32px;
      justify-content: center;
      border-bottom: 1px var(--color-tertiary-1) solid;
      .Tab {
        .Title {
          margin-right: 0; }
        .Counter {
          display: none; } } } } }
