.Footer {
  padding: 24px 0px;
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: start; }
.Footer-LinkBlock {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  a {
    color: var(--color-tertiary-6); }
  .Title {
    color: var(--color-tertiary-10); }
  .Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    color: var(--color-tertiary-6); } }
.Copyrights {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  color: var(--color-tertiary-6);
  .Contacts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .Phone {
      display: flex;
      align-items: center;
      .Icon {
        font-size: 24px;
        margin-right: 4px; } }

    .SocialLinks {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      grid-gap: 24px;
      font-size: 24px;
      a {
        color: var(--color-tertiary-6);
        &:hover {
          color: var(--color-primary-10); } } } } }

@media screen and ( max-width: 1366px ) {
  .Footer {
    grid-template-columns: 1fr;
    .Copyrights {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px;
      padding: 16px; } }
  .Footer-LinkBlock {
    border-bottom: 1px var(--color-tertiary-1) solid;
    padding: 16px 0px;
    .Title, .Content {
      padding: 0px 16px; } } }
