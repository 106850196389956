.Site-PageHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--shadow-s);
  min-height: 120px;
  .LeftSide {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    align-content: start;
    .Caption-Medium {
      color: var(--color-tertiary-6);
      margin-bottom: -28px; } } }

@media screen and ( max-width: 576px ) {
  .Site-PageHeader {
    .SectionTitle {
      text-align: center; }
    .LeftSide, .Stepper {
      width: 100% !important; }
    .Stepper {
      .Title {
        display: none; } }
    .TabsController {
      grid-gap: 32px;
      justify-content: center; } } }
