.Header {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  height: 62px; }
.Header-FormData {
  justify-self: end;
  margin-right: 80px; }
.LeftSide {
  display: flex;
  align-items: center; }
.Header-Caption {
  margin-left: 40px;
  .Overline-Medium {
    color: var(--color-tertiary-6); } }
.Header_theme_white {
  background-color: #fff;
  border-bottom: 1px var(--color-tertiary-1) solid;
  .CartButton {
    .Icon {
      color: var(--color-tertiary-6); } }
  .LangPicker {
    color: var(--color-tertiary-10); } }
.Header-Controlls {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 24px;
  align-items: center;
  position: relative;
  .ProfilePopUp {
    top: 48px;
    right: 0;
    position: absolute; }
  .Devider {
    width: 1px;
    height: 24px;
    background-color: var(--color-tertiary-2); } }

@media screen and ( max-width: 1366px ) {
  .Header {
    // position: sticky
    // top: 0
    // left: 0
    z-index: 100; }
  .Header-Caption {
    display: none; } }
