.SocialAuth {
  width: 100%;
  margin: 24px 0px;
  .SocialAuth-Title {
    width: 100%;
    position: relative;
    text-align: center;
    span {
      color: var(--color-tertiary-6);
      padding: 0px 16px;
      background-color: #fff;
      position: relative;
      z-index: 2; }
    &::before {
      width: 100%;
      content: '';
      height: 1px;
      background-color: var(--color-tertiary-2);
      display: block;
      position: absolute;
      top: 50%; } }
  .SocialAuth-Buttons {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px; } }
