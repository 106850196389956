.PaymentStatusPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .Illustration {
    width: 375px;
    height: 375px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center; } }
  .PaymentStatus-Text {
    color: var(--color-tertiary-8); }
  .Content {
    margin-top: 24px;
    text-align: center;
    .Title {
      margin-bottom: 8px; } }
  .Buttons {
    margin-top: 32px;
    .Button_theme_primary {
      margin-left: 16px; } } }

@media screen and ( max-width: 1366px ) {
  .PaymentStatusPage {
    .Buttons {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px;
      width: 100%;
      padding: 0px 16px;
      .Button {
        width: 100%; }
      .Button_theme_primary {
        margin-left: 0 !important; } } } }
