.HowItWorks {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  justify-items: center;
  color: #fff;
  h1 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700; } }
