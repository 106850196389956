.Orders {
  .Buttons {
    margin-top: 24px; } }
.Orders-Head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px; }

@media screen and ( max-width: 1366px ) {
  .Orders {
    h2 {
      display: none; }
    .TabGroup {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .Tab {
        text-align: center; } }
    .Buttons {
      width: 100%;
      .Button {
        width: 100%; } } } }
